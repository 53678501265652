import React, { useEffect, useState } from "react";
import FilterIcon from "../../icons/FilterIcon";
import Dropdown from "../dropdown/Dropdown";
import { useTranslation } from "react-i18next";
import useIsMobileDevice from "../../../hooks/useIsMobileDevice";

interface FilterPopoverProps{
    options: any,
    onFilterChange: (filter: any) => void,
    selected?: any
}

const FilterPopover: React.FC<FilterPopoverProps> = ({options, onFilterChange, selected}) => {
    const { t } = useTranslation();
    const isMobile = useIsMobileDevice();
    const [filter, setFilter] = useState<any[]>([])
    const [selectedFilters, setSelectedFilters] = useState(localStorage.getItem("receiptFilter"))
    const parsedSelectedFilters = selectedFilters ? JSON.parse(selectedFilters) : [0]



    const handleFilterChange = (item: any) => {
        setFilter(item);
    };
    useEffect(() => {
        onFilterChange(filter)
    }, [filter])

    return(
    <div className="flex justify-end my-2 items-start gap-2 mt-16">
        <span className="mt-2">

        <FilterIcon w={isMobile ? "16px" : ""} h={isMobile ? "16px" : ""}/>
        </span>
        <div className="w-5/12 md:w-[19.8%] flex items-center ">
        <Dropdown classes="bg-white text-xs md:text-base" options={options} onChange={handleFilterChange} title={options[0].label} isMultiSelect={true} isReceiptTable={true} defaultValue={parsedSelectedFilters} selected={selected}/>
        </div>
    </div>)
}

export default FilterPopover;  