import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import SalesReports from "../../components/salesReports/SalesReports";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";  
import { API_ROUTES } from "../../config";
import useAxios from "../../hooks/useAxios";
import ZReports from "../../components/zReports/ZReports";
import PeriodReports from "../../components/periodReports/PeriodReports";
import { getCookie } from "../../utilities/cookies";
import SoldProductsReports from "../../components/soldProductsReports/SoldProductsReports";
import PercentageReport from "../../components/percentageReport/PercentageReport";
import MainCategoryReport from "../../components/mainCategoryReport/MainCategoryReport";
import ReceiptReports from "../../components/receiptReports/ReceiptReport";


const Reports: React.FC = () => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const [liveData, setLiveData] = useState<any>();
  const [avgFoodSpendingData, setAvgFoodSpendingData] = useState([]);
  const [paymentData, setPaymentData] = useState<any>([]);
  const [starsData, setStarsData] = useState([]);
  const [tablesAndSeatsData, setTablesAndSeatsData] = useState([]);
  const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);
  const { fetchData } = useAxios();
  const token = getCookie("token");
  // const UserMenus = localStorage.getItem("UserMenus");


  const menuId = localStorage.getItem("MI")

  useEffect(() => {
    if (
      location.pathname === "/reports/sales"
    ) {
      if (menuId) {
        const getData = async () => {
          const requestBody = {
            Language: i18n.language,
            IdMenu: menuId,
          };

          setIsLoadingLiveData(true);

          try {
            const apiResponse = await fetchData(API_ROUTES.getLiveData, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });
            setAvgFoodSpendingData(
              apiResponse.data.AdditionalInformation.CategoryTypesPercentages,
            );
            setStarsData(apiResponse.data.AdditionalInformation.FeedbackStars);
            setLiveData(apiResponse.data);
            setTablesAndSeatsData(apiResponse.data.AdditionalInformation);
            setPaymentData(apiResponse.data.Payments);
          } catch (error) {
            console.error("Error fetching data:", error);
            setAvgFoodSpendingData([]);
            setStarsData([]);
            setLiveData(null);
            setTablesAndSeatsData([]);
            setPaymentData([]);
          } finally {
            setIsLoadingLiveData(false);
          }
        };

        getData();
      }
    }
  }, [menuId, location.pathname]);

  useEffect(() => {
    localStorage.setItem('receiptFilter', "[0]")
  }, [location])

  return (
    <div className="Reports w-full ">
      {location.pathname === "/reports/sales" ? (
        <div className="w-full mx-auto overflow-x-hidden">  
          <div className="fixed left-0 md:left-[20%] right-0 ">

          {liveData ? (
            <SalesReports
              totalTurnoverValue={liveData.CalculatedTotals.TotalTurnover}
              pendingValue={liveData.CalculatedTotals.PendingTurnover}
              forecastValue={liveData.CalculatedTotals.Forecast}
              discountValue={liveData.CalculatedTotals.Discounts}
              occupiedValue={liveData.AdditionalInformation.TakenTablesCount}
              freeValue={liveData.AdditionalInformation.FreeTablesCount}
              avgBillValue={liveData.CalculatedTotals.AvgPerBill}
              avgPPValue={liveData.CalculatedTotals.AvgPerPerson}
              avgFoodSpendingData={avgFoodSpendingData}
              starsData={starsData}
              paymentData={paymentData}
              liveData={liveData}
            />
          ) : (
            <SalesReports
              totalTurnoverValue="0"
              pendingValue="0"
              forecastValue="0"
              discountValue="0"
              occupiedValue="0"
              freeValue="0"
              avgBillValue="0"
              avgPPValue="0"
              avgFoodSpendingData={avgFoodSpendingData}
              starsData={starsData}
              paymentData={paymentData}
              liveData={liveData}
            />
          )}
          </div>
        </div>
      ) : location.pathname === "/reports/zreport" ? (
        <div className="w-full overflow-x-hidden">
          <div className="fixed left-0 md:left-[20%] right-0 ">
            {menuId && <ZReports/>}
          </div>
        </div>
      ) : location.pathname === "/reports/periodReport" ? (
        <div className="w-full overflow-x-hidden">
          <div className="fixed left-0 md:left-[20%] right-0 ">
            {<PeriodReports menuId={menuId ? menuId : ""}/>}
          </div>
        </div>
      ) : location.pathname === "/reports/soldProducts" ? (
        <div className="w-full overflow-x-hidden">
        <div className="fixed left-0 md:left-[20%] right-0 ">
          {<SoldProductsReports/>}
        </div>
        
      </div>
      ) : location.pathname === "/reports/percentageReport" ? (
        <div className="w-full overflow-x-hidden">
        <div className="fixed left-0 md:left-[20%] right-0 ">
          <PercentageReport/>
        </div>
      </div>
    ) : location.pathname === "/reports/mainCategory" ? (
      <div className="w-full overflow-x-hidden">
        <div className="fixed left-0 md:left-[20%] right-0">
          <MainCategoryReport/>
        </div>
    </div>
  ) 
    : location.pathname === "/reports/receiptReport" ? (
      <div className="w-full overflow-x-hidden">
        <div className="fixed left-0 md:left-[20%] right-0 ">
          <ReceiptReports/>
        </div>
      </div>
    ) : (<></>)
      }

    </div>
  );
};

export default withTranslation()(Reports);
