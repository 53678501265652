import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DropdownProps } from "./Dropdown.type";
import { v4 as uuidv4 } from 'uuid';
import ArrowUpIcon from "../../icons/ArrowUpIcon";
import ArrowDownIcon from "../../icons/ArrowDownIcon";
import "./dropdown.css"

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onChange,
  classes,
  defaultValue,
  title,
  isMultiSelect = true,
  isTaxDropdown = false,
  iconColor,
  isReceiptTable = false,
  selected
}) => {
  const [selectedValues, setSelectedValues] = useState<any[]>(
    defaultValue ? defaultValue : [options[0]?.value]
  );

//  console.log(defaultValue, "defaultValue")
//  console.log(selectedValues, "selected values")
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeDropdown = (event: MouseEvent) => {
      if (isOpen && selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", closeDropdown);

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [isOpen]);

  const handleSelect = (value: any) => {
    let newSelectedValues = [];

    if (isMultiSelect) {
      if (isTaxDropdown) {
        if (value == -1) {
          newSelectedValues = [-1];
        } else {
          if (selectedValues.includes(value)) {
            newSelectedValues = selectedValues.filter(item => item !== value);
          } else {
            newSelectedValues = selectedValues.filter((item: any) => item !== -1).concat(value);
          }
        }
      } else {
        if (value == "0") {
          newSelectedValues = [0];
        } else {
          if (selectedValues.includes(value)) {
            newSelectedValues = selectedValues.filter(item => item !== value);
          } else {
            newSelectedValues = selectedValues.filter((item: any) => item !== 0).concat(value);
          }
        }
      }
    } else {
      newSelectedValues = [value];
      setIsOpen(false); // Close the dropdown when an option is selected in single-select mode
    }

    if (isReceiptTable && newSelectedValues.length === 0) {
      newSelectedValues = [0];
    }

    setSelectedValues(newSelectedValues);
    onChange(newSelectedValues);
  };
  useEffect(() => {
    if(selected && isReceiptTable){
      setSelectedValues(selected)
    }
  },[selected])
  // const selectClasses = classNames(
  //   "w-full text-start py-2.5 text-black bg-transparent border-0 dark:bg-white focus:outline-none focus:ring-0 focus:border-gray-200",
  //   classes
  // );

  const renderSelectedValues = () => {
    if (selectedValues.length === 0 || selectedValues.includes("0")) {
      return title;
    }
  
    return selectedValues.map((value) => {
      const selectedOption = options.find(option => option.value === value);
      if (!selectedOption) return null;
  
      // Create an element for each selected option's label and icon.
      return (
        <span key={value} style={{ display: 'inline-flex', alignItems: 'center' }}>
          {selectedOption.label}
          {selectedOption.icon && <span style={{ marginLeft: '4px' }}>{selectedOption.icon}</span>}
        </span>
      );
    });
  };
  return (
    <div className={`relative text-start w-full text-xs md:text-base ${classes} `} ref={selectRef}>
      <button
        type="button"
        className={`w-full text-start px-2 bg-transparent border rounded-lg focus:outline-none flex items-center justify-between py-1 md:py-2 ${classes}`} style={{border: "1px solid #CED1D9"}}
        onClick={() => setIsOpen(!isOpen)}
      >
        {!isMultiSelect ? renderSelectedValues() : title}
       
        <span className="float-right">
        {isOpen ? <ArrowUpIcon color={iconColor && iconColor}/> : <ArrowDownIcon color={iconColor && iconColor}/>}
        </span>
      </button>
      {isOpen && (
        <ul className={` absolute z-10 mt-2 w-full max-h-40 overflow-y-auto bg-white border border-gray-300 rounded-lg shadow-lg ${classes}`}>
          {options.map((option) => (
            <li
              key={uuidv4()}
              className={classNames("py-2 px-4 hover:bg-gray-200 cursor-pointer border-b flex items-center", {
                "bg-gray-200": selectedValues.includes(option.value)
              })}
              onClick={() => handleSelect(option.value)}
            >
              {isMultiSelect && (
          <input
          type="checkbox"
          checked={selectedValues.includes(option.value)}
          onChange={() => handleSelect(option.value)}
          className="mr-1 custom-checkbox"
        />
              )}
              {option.label}
              {option.icon && <span className="ml-1">{option.icon}</span>}
            </li>
          ))}
        </ul>
      )}
    <div className="flex flex-wrap">
  {isMultiSelect && selectedValues.map((value: any) => {
    const option: any = options.find(option => option.value === value);
    // Check if the option exists and its value is not 0
    if (option && option.value !== 0) {
      return (
        <div key={uuidv4()} className="pl-2 flex items-center mb-1">
          <input
            type="checkbox"
            checked={true}
            onChange={() => handleSelect(value)}
            className="mr-1 custom-checkbox"
          />
          <span>{option?.label}</span>
        </div>
      );
    }
    return null; // Return null if the condition isn't met
  })}
</div>

    </div>
  );
};

export default Dropdown;
