import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import { SoldProductsFiltersProps } from "./SoldProductsReportFilters.type";
import Select from "../common/select/Select";
import { useTranslation } from "react-i18next";
import { API_ROUTES } from "../../config";
import i18n from "../../i18n";
import useAxios from "../../hooks/useAxios";
import { useLocation } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";
import Wrapper from "../common/wrapper/Wrapper";
import DropdownComponent from "./DropdownComponent";
import useOptions from "../../hooks/useOptions";
import { useSoldProductsFilters } from "../../hooks/useSoldProductsFilters";
import { useFetchSoldProductsFiltersData } from "../../hooks/useFetchSoldProductsFiltersData";
import useDateFormatter from "../../hooks/useDateFormatter";
import useDateDisabling from '../../hooks/useDateDesabling'; 
import CustomDatePicker from "../common/customDatePicker/customDatePicker";
import useUpdateAvailableDates from "../../hooks/useUpdateAvailableDates";
import CustomTimePicker from "../common/customTimePicker/CustomTimePicker";
import useTimeConverter from "../../hooks/useTimeConverter";
import ArrowDownIcon from "../icons/ArrowDownIcon";
import ArrowUpIcon from "../icons/ArrowUpIcon";
import Dropdown from "../common/dropdown/Dropdown";
import dayjs from "dayjs";
import ExportFilterActions from "../common/reportAction/ExportFilterActions";


const SoldProductsReportFilters: React.FC<SoldProductsFiltersProps> = ({
  sendFiltersToParent,
  exportType,
  fileUrl, 
  getDataBe, 
  handleExportChange
}) => {
  const menuId = localStorage.getItem("MI");
  const lsMenuId = localStorage.getItem("menuId");
  const parsedLsMenuId = lsMenuId && JSON.parse(lsMenuId);
  const option = parsedLsMenuId ? { value: parsedLsMenuId.IdMenu, label: parsedLsMenuId.MenuName } : null;
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const UserMenus = localStorage.getItem("UserMenus");
  const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;
  const options = UserMenusParsed.map((location: any) => {
    return { value: location.IdMenu, label: location.MenuName };
  });
  const now = new Date();
  const { formatDateSP } = useDateFormatter();
  const {t}   = useTranslation();
  const [selectedLocation, setSelectedLocation] = useState(options.length > 0 ? options[0].value : null);
  const [subCategoryOptions, setSubCategoryOptions] = useState<any>([0]);
  const [productsOptions, setProductsOptions] = useState<any>([0])
  const token = localStorage.getItem("token");
  const { fetchData } = useAxios();
  const location = useLocation();
  const [fromDate, setFromDate] = useState<any>(formatDateSP(new Date(now.getFullYear(), now.getMonth(), 1)));
  const [toDate, setToDate] = useState<any>(formatDateSP(new Date(now.getFullYear(), now.getMonth() + 1, 0)));
  const [fromTime, setFromTime] = useState("00:00");
  const [toTime, setToTime] = useState("23:00");
  const [selectedExportType, setSelectedExportType] = useState<any>("0")
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [allowedDatesFromPicker, setAllowedDatesFromPicker] = useState<any>();
  const [allowedDatesToPicker, setAllowedDatesToPicker] = useState<any>();
  const isTaxParentGroup = JSON.parse(localStorage.getItem("toggle_1") || "false");
  const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false);
  const {convertTimeToHour} = useTimeConverter();
  
  const dateRangeOptions = [
    {value: "date", label: t("dateRange.custom")},
    {value: "week", label:  t("dateRange.weekly")},
    {value: "month", label: t("dateRange.monthly")},
    {value: "quarter", label:  t("dateRange.quarterly")},
    {value: "year", label:  t("dateRange.yearly")}
  ]
  const [pickerMode, setPickerMode] = useState(dateRangeOptions[0].value);

  const { timePeriod, mainCategory, subCategory, products, taxParentType, taxType } = useFetchSoldProductsFiltersData(menuId, token);

  const { timePeriodOptions, mainCategoryOptions, taxParentTypeOptions, taxTypeOptions } = useOptions(
    timePeriod,
    mainCategory,
    taxParentType,
    taxType
  );
  const {
    selectedTimePeriod,
    selectedTax,
    selectedSubCategory,
    selectedMainCategory,
    selectedProducts,
    handleTimePeriodChange,
    handleTaxChange,
    handleSubCategoryChange,
    handleMainCategoryChange,
    handleProductsChange,
    handleParentTaxChange,
  } = useSoldProductsFilters(taxTypeOptions);
  //get allowed dates
  useEffect(() => {
    if (
       location.pathname === "/reports/soldProducts"
    ) {
      if (menuId) {
        
        const getData = async () => {
          const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
          const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
          const requestBody = {
            IdMenu: menuId,
            "TargetDate": formatDateSP(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
            "TargetDateTo": formatDateSP(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
          };


          try {
            const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });
            if(apiResponse.data){
              const targetDates = apiResponse.data.map((date: any) => new Date(date.TargetDate));
              setAllowedDatesFromPicker(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()));
              setAllowedDatesToPicker(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()))
            }
          } catch (error) {

          } finally {
        
          }
        };

        getData();
      }
    }
  }, [selectedLocation, location.pathname]);

  const handleSelectChange = (selectedOption: any) => {
    const selectedLocationData = UserMenusParsed.find(
      (location: any) => location.IdMenu === selectedOption
    );
    const selectedMenuArray = { IdMenu: selectedLocationData.IdMenu, MenuName: selectedLocationData.MenuName }
    localStorage.setItem("menuId", JSON.stringify(selectedMenuArray))
    localStorage.setItem("MI", JSON.stringify(selectedMenuArray.IdMenu))

    if(selectedLocationData) {
      localStorage.setItem("longitude", selectedLocationData.Longitude);
      localStorage.setItem("latitude", selectedLocationData.Latitude);
      localStorage.setItem("fullAddress", selectedLocationData.FullAddress)
    }
    window.location.reload();

  };
  
  const [allFilters, setAllFilters] = useState<any>({
    IdMenu: "",
    TargetDate: "",
    TargetDateTo: "",
    Language: "",
    FilterType: "1",
    HourFilterFrom: "0",
    HourFilterTo: "24",
    ReportType: "1",
    ExportType: "",
    User: "",
    MainCategoryIds: "",
    SubCategoryIds: "",
    ProductIds: "",
    TaxIds: ""
  });
  //set all filter
  useEffect(() => {
    setAllFilters({
      IdMenu: menuId?.toString(),
      TargetDate: fromDate,
      TargetDateTo: toDate,
      Language: i18n.resolvedLanguage,
      FilterType: selectedTimePeriod?.toString(),
      HourFilterFrom: convertTimeToHour(fromTime),
      HourFilterTo: convertTimeToHour(toTime),
      ReportType: "1",
      ExportType: +selectedExportType,
      User: "",
      MainCategoryIds: selectedMainCategory && selectedMainCategory.toString(),
      SubCategoryIds: selectedSubCategory?.toString(),
      ProductIds: selectedProducts?.toString(),
      TaxIds: selectedTax?.toString()
    })
  }, [selectedProducts, menuId, fromDate, toDate, i18n, fromTime, toTime, selectedExportType, selectedMainCategory, selectedSubCategory, selectedTax, selectedTimePeriod])
  //send filters to parent
  useEffect(() => {
    sendFiltersToParent(allFilters)
  },[allFilters])

  //update options
  useEffect(() => {
    const updateOptions = () => {
      // Update Sub Categories
      if (selectedMainCategory?.length > 0) {
        const filteredSubCategories = subCategory.filter((category: any) =>
          selectedMainCategory.includes(category.IdParent) || category.IdParent === 0
        );
        const mappedSubCategories = filteredSubCategories.map((category: any) => ({
          value: category?.Id,
          label: category?.Description
        }));
        setSubCategoryOptions(mappedSubCategories);
      } else {
        setSubCategoryOptions([{ value: subCategoryOptions?.[0]?.value, label: subCategoryOptions?.[0]?.label }]);
      }

      // Update Products
      if (selectedSubCategory?.length > 0 && products.length > 0) {
        const filteredProduct = products.filter(
          (product: any) => selectedSubCategory.includes(parseInt(product.IdParent)) || product.IdParent === 0
        );
        const mappedProducts = filteredProduct.map((product: any) => ({
          value: product?.Id,
          label: product?.Description
        }));
        setProductsOptions(mappedProducts);
      } else {
        setProductsOptions([{ value: products?.[0]?.Id, label: products?.[0]?.Description }]);
      }
    };

    updateOptions();
  }, [selectedMainCategory, selectedSubCategory, products, subCategory]);
    
  const { disabledDate, disabledToDate } = useDateDisabling({ allowedDatesFromPicker, allowedDatesToPicker });

  const toggleFilters = () => {
    setIsOpenFilters(!isOpenFilters)
  };


  useEffect(() => {
    const parsedFromDate = dayjs(fromDate, 'DD/MM/YYYY');
    if(pickerMode === "week") {
      const toDate = parsedFromDate.endOf('week');
      const formattedToDate = toDate.format('DD/MM/YYYY');
      setToDate(formattedToDate)
    } else if (pickerMode === "month") {
      const toDate = parsedFromDate.endOf("month");
      const formattedToDate = toDate.format("DD/MM/YYYY");
      setToDate(formattedToDate)
    } else if (pickerMode === "year") {
      const toDate = parsedFromDate.endOf("year");
      const formattedToDate = toDate.format("DD/MM/YYYY");
      setToDate(formattedToDate)
    } else if (pickerMode === "quarter") {
      const toDate = parsedFromDate.endOf("quarter");
      const formattedToDate = toDate.format("DD/MM/YYYY");
      setToDate(formattedToDate)
    }
  }, [fromDate, pickerMode])



  const handleModeChange = (selectedMode: any) => {
    const newMode = selectedMode[0]; 
    setPickerMode(newMode);
  };
  const currentDate = new Date();


  const getNewFromDate = (mode: string): Date => {
    const now = new Date();
    switch (mode) {
      case "month":
        return new Date(now.getFullYear(), now.getMonth(), 1); // First day of the current month
      case "week":
        const firstDayOfWeek = new Date(now);
        const dayOfWeek = firstDayOfWeek.getDay() || 7; // Ensure 0 (Sunday) is treated as 7
        firstDayOfWeek.setDate(now.getDate() - dayOfWeek + 1); // Set to the first day of the week (Monday)
        return firstDayOfWeek;
      case "quarter":
        const quarterStartMonth = Math.floor(now.getMonth() / 3) * 3; // Start of the quarter
        return new Date(now.getFullYear(), quarterStartMonth, 1);
      case "year":
        return new Date(now.getFullYear(), 0, 1); // First day of the year
      default:
        return (new Date(currentDate.getFullYear(), currentDate.getMonth(), 1))
    }
  };
  
  const getNewToDate = (mode: string): Date => {
    const now = new Date();
    switch (mode) {
      case "month":
        return new Date(now.getFullYear(), now.getMonth() + 1, 0); // Last day of the current month
      case "week":
        const lastDayOfWeek = new Date(now);
        const dayOfWeek = lastDayOfWeek.getDay() || 7; // Ensure 0 (Sunday) is treated as 7
        lastDayOfWeek.setDate(now.getDate() + (7 - dayOfWeek)); // Set to the last day of the week (Sunday)
        return lastDayOfWeek;
      case "quarter":
        const quarterEndMonth = Math.floor(now.getMonth() / 3) * 3 + 2; // End of the quarter
        return new Date(now.getFullYear(), quarterEndMonth + 1, 0); // Last day of the quarter
      case "year":
        return new Date(now.getFullYear(), 11, 31); // Last day of the year
      default:
        return (new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))
    }
  };

  useEffect(() => {
    // Update fromDate and toDate when pickerMode changes
    const newFromDate = formatDateSP(getNewFromDate(pickerMode));
    const newToDate = formatDateSP(getNewToDate(pickerMode));

    setFromDate(newFromDate);
    setToDate(newToDate);
  }, [pickerMode]);

  const { updateAvailableDates } = useUpdateAvailableDates({ 
    menuId, 
    token, 
    locationPathname: location.pathname 
  });

  return (
     <Wrapper onClick={() => {}} classes={`pt-10 md:pt-20 flex flex-col items-center justify-center md:pb-4 fixed left-0 md:left-[20%] right-0 bg-[#FFFFFF40] backdrop-blur-[35px] z-10  ${isOpenFilters ? "pb-8 border-b": ""}`}>

        <div className="w-[95%] mx-auto flex flex-col md:gap-4 ">

          <div className="w-full">
          <Heading
            align="left"
            children={t("soldProducts.title")}
            classes="pt-4 md:pt-2 min-w-full text-[22px] md:text-4xl"
            color=""
            size="h1"
          />
          <Text
            align=""
            children={t("soldProducts.subTitle")}
            classes="text-[15px] md:text-base font-thin"
            color="text-custom-textGraySec"
            size=""
          />
        </div>

        <Select
          classes="md:hidden flex justify-center rounded-lg border border-custom-bgBlue bg-white md:w-1/3 relative mt-3 py-[7px]"
          options={options}
          onChange={handleSelectChange}
          defaultValue={option}
          />
          </div>
          <div className="w-[95%] flex flex-col md:flex-row justify-between items-end mb-4">
          <div className="w-full md:w-1/6">
          <p className="text-sm md:text-base mb-2 mt-2">{t("dateRange.title")}</p>

          <Dropdown isMultiSelect={false} onChange={handleModeChange} options={dateRangeOptions} title={dateRangeOptions.length > 0 && dateRangeOptions[0].label} />
          </div>
          <div className="w-full md:w-2/5 mt-2 flex gap-2 mb-0">
   

        <div className="text-left w-full flex gap-2">
          <div className="w-1/2">
          <p className="text-sm md:text-base mb-2 ">{t("soldProducts.fromToDate")}</p>
              {fromDate && toTime && 

              <span className="grid grid-cols-2 gap-2">
                {fromDate && 
                <CustomDatePicker
                dateValue={fromDate}
                onDateChange={setFromDate}
                disabledDate={disabledDate}
                handleMonthChange={(date: any) => {updateAvailableDates(date, setAllowedDatesFromPicker)}}
                placeholder={t("datePicker.placeholder")}
                allowedDates={allowedDatesFromPicker}
                mode={pickerMode}
                />
              }
              <CustomDatePicker
              dateValue={toDate}
              onDateChange={setToDate}
              disabledDate={disabledToDate}
              handleMonthChange={(date: any) => {updateAvailableDates(date, setAllowedDatesToPicker)}}
              placeholder={t("datePicker.placeholder")}
              isToDatePicker={true}
              allowedDates={allowedDatesToPicker}
              mode={pickerMode}
              />
              </span>
      }
              </div>

        <div className="text-left w-1/2">
          <p className="text-sm md:text-base mb-2">{t("soldProducts.fromToTime")}</p>
          <span className="grid grid-cols-2 gap-2">
              <CustomTimePicker
                selectedTime={fromTime}
                onTimeChange={setFromTime}
                />
              <CustomTimePicker
                selectedTime={toTime}
                onTimeChange={setToTime}
                isToTime={true}
                />
          </span>
        </div>
        </div>
        </div>
         
        </div>
        <div onClick={toggleFilters} className="text-xs md:text-base items-center flex justify-between w-[95%] border rounded-lg px-2 py-1.5 md:py-2 bg-white mb-4">{t("soldProducts.filters")} {isOpenFilters ? <ArrowUpIcon/> : <ArrowDownIcon />} </div>
        {isOpenFilters ? 
        <div className="w-[95%] grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">

         <DropdownComponent
        label={t("soldProducts.timePeriod")}
        options={timePeriodOptions}
        onChange={handleTimePeriodChange}
        defaultValue={1}
      />

        <DropdownComponent
        label={t("soldProducts.mainCategory")}
        options={mainCategoryOptions}
        onChange={handleMainCategoryChange}
        isMultiSelect
      />
        <DropdownComponent
        label={t("soldProducts.subCategory")}
        options={subCategoryOptions}
        onChange={handleSubCategoryChange}
        isMultiSelect
      />

        <DropdownComponent
        label={t("soldProducts.products")}
        options={productsOptions}
        onChange={handleProductsChange}
        isMultiSelect
      />
        {isTaxParentGroup ? <DropdownComponent
        label={t("soldProducts.taxParentType")}
        options={taxParentTypeOptions}
        onChange={handleParentTaxChange}
        isMultiSelect
      /> : <></>}
        

        <DropdownComponent
        label={t("soldProducts.taxType")}
        options={taxTypeOptions}
        onChange={handleTaxChange}
        isMultiSelect
      />
        </div> 
        :
        <></>}
        <div className="w-[95%]">
        <ExportFilterActions
          fileUrl={fileUrl}
          exportType={exportType}
          handleExportChange={handleExportChange}
          fetchReportData={getDataBe}
        />
        </div>
       
    </Wrapper >
  );
};

export default SoldProductsReportFilters;
