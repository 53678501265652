import { useContext, useEffect, useState } from "react";
import Select from "../common/select/Select";
import Wrapper from "../common/wrapper/Wrapper";
import { useTranslation } from "react-i18next";
import useAxios from "../../hooks/useAxios";
import { API_ROUTES } from "../../config";
import { useLocation } from "react-router-dom";
import i18n from "../../i18n";
import 'react-datepicker/dist/react-datepicker.css';
import { MainCategoryReportFiltersProps } from "./MainCategoryFilters.type";
import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";
import useUpdateAvailableDates from "../../hooks/useUpdateAvailableDates";
import useDateDisabling from "../../hooks/useDateDesabling";
import CustomDatePicker from "../common/customDatePicker/customDatePicker";
import useDateFormatter from "../../hooks/useDateFormatter";
import CustomTimePicker from "../common/customTimePicker/CustomTimePicker";
import useTimeConverter from "../../hooks/useTimeConverter";
import { PrivilegesContext } from "../../context/privilegesContext";
import Dropdown from "../common/dropdown/Dropdown";
import dayjs from "dayjs";
import ExportFilterActions from "../common/reportAction/ExportFilterActions";

const MainCategoryFilters: React.FC<MainCategoryReportFiltersProps> = ({sendFiltersToParent, exportType, fileUrl, getDataBe, handleExportChange}) => {
  const { t } = useTranslation();
  const now = new Date();
  const UserMenus = localStorage.getItem("UserMenus");
  const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;
  const options = UserMenusParsed.map((location: any) => {
    return { value: location.IdMenu, label: location.MenuName };
  });
  const { formatDateSP } = useDateFormatter();
  const [selectedLocation, setSelectedLocation] = useState(options.length > 0 ? options[0].value : null);
  const [allowedDatesFromPicker, setAllowedDatesFromPicker] = useState<any>();
  const [allowedDatesToPicker, setAllowedDatesToPicker] = useState<any>();
  const [fromDate, setFromDate] = useState<any>(formatDateSP(new Date(now.getFullYear(), now.getMonth(), 1)));
  const [toDate, setToDate] = useState<any>(formatDateSP(new Date(now.getFullYear(), now.getMonth() + 1, 0)));
  const [fromTime, setFromTime] = useState("00:00");
  const [toTime, setToTime] = useState("23:00");
  const oneWeekAgo = new Date(now);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const { fetchData } = useAxios();
  const token = localStorage.getItem("token");
  const location = useLocation();
  // const menuId = selectedLocation;
  // const {menuId, setMenuId } = useContext(PrivilegesContext);
  const menuId = localStorage.getItem("MI")
  const lsMenuId = localStorage.getItem("menuId");
  const parsedLsMenuId = lsMenuId && JSON.parse(lsMenuId);
  const option = parsedLsMenuId ? { value: parsedLsMenuId.IdMenu, label: parsedLsMenuId.MenuName } : null;
  const dateRangeOptions = [
    {value: "date", label: t("dateRange.custom")},
    {value: "week", label:  t("dateRange.weekly")},
    {value: "month", label: t("dateRange.monthly")},
    {value: "quarter", label:  t("dateRange.quarterly")},
    {value: "year", label:  t("dateRange.yearly")}
  ]
  const [pickerMode, setPickerMode] = useState(dateRangeOptions[0].value);
  const [selectedExportType, setSelectedExportType] = useState<any>("0");
  const {convertTimeToHour} = useTimeConverter();
  const [allFilters, setAllFilters] = useState<any>({
    "IdMenu": "",
    "TargetDate": "",
    "TargetDateTo": "",
    "Language": "",
    "HourFilterFrom": "",
    "HourFilterTo": "",
    "ExportType": "",
    "MainCategoryIds": "",
    "SubCategoryIds": "",
    "ProductIds": "",
    "TaxIds": "",
    "ReportType": 2
  });
  
  const handleSelectChange = (selectedOption: any) => {
    const selectedLocationData = UserMenusParsed.find(
      (location: any) => location.IdMenu === selectedOption
    );
    const selectedMenuArray = { IdMenu: selectedLocationData.IdMenu, MenuName: selectedLocationData.MenuName }
    localStorage.setItem("menuId", JSON.stringify(selectedMenuArray))
    localStorage.setItem("MI", JSON.stringify(selectedMenuArray.IdMenu))

    if(selectedLocationData) {
      localStorage.setItem("longitude", selectedLocationData.Longitude);
      localStorage.setItem("latitude", selectedLocationData.Latitude);
      localStorage.setItem("fullAddress", selectedLocationData.FullAddress)
    }
    window.location.reload();

  };

  useEffect(() => {
    sendFiltersToParent(allFilters);
  }, [allFilters, sendFiltersToParent, ]);


  useEffect(() => {
    setAllFilters({
      "IdMenu": menuId,
      TargetDate: fromDate,
      TargetDateTo: toDate,
      "Language": i18n.resolvedLanguage,
      "HourFilterFrom": convertTimeToHour(fromTime),
      "HourFilterTo": convertTimeToHour(toTime),
      "ExportType": selectedExportType.toString(),
      "MainCategoryIds": "",
      "SubCategoryIds": "",
      "ProductIds": "",
      "TaxIds": "",
      "ReportType": 2
  })
  }, [menuId, i18n.resolvedLanguage, selectedExportType, fromDate, toDate, fromTime, toTime])

  const { updateAvailableDates } = useUpdateAvailableDates({ 
    menuId, 
    token, 
    locationPathname: location.pathname 
  });


  useEffect(() => {
    const parsedFromDate = dayjs(fromDate, 'DD/MM/YYYY');
    if(pickerMode === "week") {
      const toDate = parsedFromDate.endOf('week');
      const formattedToDate = toDate.format('DD/MM/YYYY');
      setToDate(formattedToDate)
    } else if (pickerMode === "month") {
      const toDate = parsedFromDate.endOf("month");
      const formattedToDate = toDate.format("DD/MM/YYYY");
      setToDate(formattedToDate)
    } else if (pickerMode === "year") {
      const toDate = parsedFromDate.endOf("year");
      const formattedToDate = toDate.format("DD/MM/YYYY");
      setToDate(formattedToDate)
    } else if (pickerMode === "quarter") {
      const toDate = parsedFromDate.endOf("quarter");
      const formattedToDate = toDate.format("DD/MM/YYYY");
      setToDate(formattedToDate)
    }
  }, [fromDate, pickerMode])



  const handleModeChange = (selectedMode: any) => {
    const newMode = selectedMode[0]; 
    setPickerMode(newMode);
  };
  const currentDate = new Date();


  const getNewFromDate = (mode: string): Date => {
    const now = new Date();
    switch (mode) {
      case "month":
        return new Date(now.getFullYear(), now.getMonth(), 1); // First day of the current month
      case "week":
        const firstDayOfWeek = new Date(now);
        const dayOfWeek = firstDayOfWeek.getDay() || 7; // Ensure 0 (Sunday) is treated as 7
        firstDayOfWeek.setDate(now.getDate() - dayOfWeek + 1); // Set to the first day of the week (Monday)
        return firstDayOfWeek;
      case "quarter":
        const quarterStartMonth = Math.floor(now.getMonth() / 3) * 3; // Start of the quarter
        return new Date(now.getFullYear(), quarterStartMonth, 1);
      case "year":
        return new Date(now.getFullYear(), 0, 1); // First day of the year
      default:
        return (new Date(currentDate.getFullYear(), currentDate.getMonth(), 1))
    }
  };
  
  const getNewToDate = (mode: string): Date => {
    const now = new Date();
    switch (mode) {
      case "month":
        return new Date(now.getFullYear(), now.getMonth() + 1, 0); // Last day of the current month
      case "week":
        const lastDayOfWeek = new Date(now);
        const dayOfWeek = lastDayOfWeek.getDay() || 7; // Ensure 0 (Sunday) is treated as 7
        lastDayOfWeek.setDate(now.getDate() + (7 - dayOfWeek)); // Set to the last day of the week (Sunday)
        return lastDayOfWeek;
      case "quarter":
        const quarterEndMonth = Math.floor(now.getMonth() / 3) * 3 + 2; // End of the quarter
        return new Date(now.getFullYear(), quarterEndMonth + 1, 0); // Last day of the quarter
      case "year":
        return new Date(now.getFullYear(), 11, 31); // Last day of the year
      default:
        return (new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))
    }
  };

  useEffect(() => {
    // Update fromDate and toDate when pickerMode changes
    const newFromDate = formatDateSP(getNewFromDate(pickerMode));
    const newToDate = formatDateSP(getNewToDate(pickerMode));

    setFromDate(newFromDate);
    setToDate(newToDate);
  }, [pickerMode]);

  const { disabledDate, disabledToDate } = useDateDisabling({ allowedDatesFromPicker, allowedDatesToPicker });

  return (
    <Wrapper onClick={() => {}} classes="pt-10 md:pt-20 flex flex-col items-center justify-center md:pb-4 fixed left-0 md:left-[20%] right-0 bg-[#FFFFFF40] backdrop-blur-[35px] z-10">
        <div className="w-[95%] mx-auto flex flex-col md:gap-4 ">

        <div className="w-full">
        <Heading
          align="left"
          children={t("mainCategory.title")}
          classes="pt-4 md:pt-2 min-w-full text-[22px] md:text-4xl"
          color=""
          size="h1"
        />
        <Text
          align=""
          children={t("mainCategory.subTitle")}
          classes="text-[15px] md:text-base font-thin text-custom-textGraySec"
          color=""
          size=""
        />
      </div>

        <Select
          classes="md:hidden flex justify-center rounded-lg border border-custom-bgBlue bg-white md:w-1/3 relative mt-3 py-[7px]"
          options={options}
          onChange={handleSelectChange}
          defaultValue={option && option}
          />
          </div>
          <div className="w-[95%] flex flex-col md:flex-row justify-between items-end mb-4">
          <div className="w-full md:w-1/6">
          <p className="text-sm md:text-base mb-2 mt-2 md:mt-0">{t("dateRange.title")}</p>

          <Dropdown isMultiSelect={false} onChange={handleModeChange} options={dateRangeOptions} title={dateRangeOptions.length > 0 && dateRangeOptions[0].label} />
          </div>
          <div className="w-full md:w-2/5 mt-2 flex gap-2 mb-0">
        <div className="text-left w-1/2">
          <p className="text-sm md:text-base mb-2">{t("mainCategory.fromToDate")}</p>
              {fromDate && toDate &&
              <span className="grid grid-cols-2 gap-2">
                <CustomDatePicker
                dateValue={fromDate}
                onDateChange={setFromDate}
                disabledDate={disabledDate}
                handleMonthChange={(date: any) => {updateAvailableDates(date, setAllowedDatesFromPicker)}}
                placeholder={t("datePicker.placeholder")}
                allowedDates={allowedDatesFromPicker}
                isToDatePicker={false}
                mode={pickerMode}
                />
                <CustomDatePicker
                dateValue={toDate}
                onDateChange={setToDate}
                disabledDate={disabledToDate}
                handleMonthChange={(date: any) => {updateAvailableDates(date, setAllowedDatesToPicker)}}
                placeholder={t("datePicker.placeholder")}
                isToDatePicker={true}
                allowedDates={allowedDatesToPicker}
                mode={pickerMode}
              />
                </span>
        }
        </div>
        <div className="text-left w-1/2">
          <p className="text-sm md:text-base mb-2">{t("mainCategory.fromToTime")}</p>   
          <span className="grid grid-cols-2 gap-2">
              <CustomTimePicker
                selectedTime={fromTime}
                onTimeChange={setFromTime}
                />
              <CustomTimePicker
                selectedTime={toTime}
                onTimeChange={setToTime}
                isToTime={true}
                />
          </span>
        </div>
        </div>
        </div>
        <div className="w-[95%]">

        <ExportFilterActions
          fileUrl={fileUrl}
          exportType={exportType}
          handleExportChange={handleExportChange}
          fetchReportData={getDataBe}
          />
          </div>
    </Wrapper>
  );
};

export default MainCategoryFilters;
